import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";

// ++++++++ BILLINGS ++++++++ //

//BILLINGS BY PARKINGID
export const getParkingBillings = async (idParking) => {
  const url = `${BASE_URL}/api/v2/manager/statements/${idParking}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};
