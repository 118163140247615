import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin, getPublicToken, getToken } from "../utils";


/**
 * Calls the verify token API endpoint to verify the authenticity of the token.
 * @returns {Promise} A promise that resolves with the JSON response from the API.
 */
export const verifyTokenApi = async () => {
  const url = `${BASE_URL}/api/v2/auth/verify`;
  const json = await callApi(url, "POST", getToken(), null);
  return json;
};


// SIGNIN
export const signin = async (requestData) => {
  const url = `${BASE_URL}/api/v2/auth/signin/manager/administrator`;
  const json = await callApiLogin(url, "POST", getPublicToken(), requestData);
  console.log("json", json);
  return json;
};

// RECOVERY PASSWORD
export const recoveryPassword = async (requestData) => {
  const url = `${BASE_URL}/api/v2/auth/manager/recovery`;
  const json = await callApiInit(url, "POST", getPublicToken(), requestData);
  console.log("json", json);
  return json;
};
