import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";

// ++++++++ REPORTS ++++++++ //

// MAIN REPORT BOOKINGS
export const managerReportFail = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/report/fail/`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

export const managerReportBookings = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/report/booking/`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// INCOME REPORT SERVICE
export const managerReportIncome = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/report/bookingpay`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// EVENTS REPORT SERVICE
export const managerReportEvents = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/report/event`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};