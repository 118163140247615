import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "../components/spinner/Spinner";
import { verifyTokenApi } from "../apis/parkup-services";


/**
 * Creates a new context object.
 * @returns A new context object.
 */
// Crear el contexto
const AuthContext = createContext();

// Proveedor del contexto
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const navigate = useNavigate();
  const location = useLocation(); // Obtener la ubicación actual

  // Función para verificar el token
  /**
   * Verifies if the user token is active by making an asynchronous call to the verifyTokenApi.
   * If the token is active, it sets the user as authenticated and redirects to the home page.
   * If the token is not active, it removes the token from local storage, sets the user as not authenticated,
   * and redirects to the login page.
   * @returns None
   */
  const verifyTokenActive = async () => {
    try {
      const response = await verifyTokenApi();
     
      if (location.pathname === "/") {
        navigate("/home");
      }


      // Asume que si la respuesta es exitosa, el usuario está autenticado
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error verificando el token:", error);
      localStorage.removeItem('aet');
      setIsAuthenticated(false);
      // Solo redirigir si no está en /auth/login
      console.log(location.pathname)
      if (location.pathname !== "/auth/login" && location.pathname !== "/auth/recovery") {
        navigate("/auth/login");
      }
    } finally {
      setLoading(false); // Desactivar el estado de carga al finalizar
    }
  };

  /**
   * useEffect hook that calls the verifyTokenActive function whenever the location pathname changes.
   * @returns None
   */
  // Verificar el token al montar el componente
  useEffect(() => {
    verifyTokenActive();
  }, [location.pathname]); // Añadir location.pathname como dependencia para escuchar cambios en la ruta

  // Mostrar el spinner mientras se verifica el token
  /**
   * Renders a Spinner component if the loading state is true.
   * @param {boolean} loading - The loading state flag.
   * @returns {JSX.Element} - The Spinner component if loading is true, otherwise null.
   */
  if (loading) {
    return <Spinner />; // Mostrar el Spinner mientras se verifica el token
  }

  /**
   * Returns a JSX element that provides authentication context to its children.
   * @param {Object} props - The properties passed to the component.
   * @param {boolean} props.isAuthenticated - A boolean value indicating if the user is authenticated.
   * @param {ReactNode} props.children - The child components to be wrapped by the AuthContext.Provider.
   * @returns {JSX.Element} An AuthContext.Provider component with the provided authentication value.
   */
  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * A custom hook that provides access to the authentication context.
 * @returns The authentication context value.
 */
// Hook para acceder al contexto
export const useAuth = () => useContext(AuthContext);