import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";

// ++++++++ RASPBERRY ++++++++ //

//FORCE TO OPEN PARKING DOOR
export const forceToOpenDoor = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/hardware/open`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

//FORCE TO CLOSE PARKING DOOR
export const forceToCloseDoor = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/hardware/close`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};
