import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";

// ++++++++ PARKINGS ++++++++ //

//PARKING ALL
export const getAllParkings = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/parking/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING BY ID
export const getParkingById = async (id) => {
  const url = `${BASE_URL}/api/v2/manager/parking/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};
