import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import * as Sentry from "@sentry/react";
import { store } from "./store/index.js";
import Spinner from "./components/spinner/Spinner.js";
import { initializationService, nextService } from "./apis/bootstrap-services";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./assets/global/global.css";
import { AuthProvider } from "./context/AuthContext.js";

const initialization = async () => {
  Sentry.init({
    dsn: "https://4ec57d5a4b8474d674a8c4db316f76fb@o4505270315778048.ingest.us.sentry.io/4508411453636608",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });


  try {

    let result = await initializationService();
    localStorage.setItem("session", result.init);
    let publickey = await nextService({ data: "string" });
    localStorage.setItem("out", publickey.access_token);
    const App = lazy(() => import("./App"));

    const root = ReactDOM.createRoot(document.getElementById("root"));

    root.render(
      <React.StrictMode>
        <ReduxProvider store={store}>
          <Suspense fallback={<Spinner />}>
            <BrowserRouter>
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </Suspense>
        </ReduxProvider>
      </React.StrictMode>
    );

  } catch (error) {

    console.error(error);

  }

};

initialization();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
