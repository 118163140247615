// ++++++++ PROFILE ++++++++ //

import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";

// GET MY PROFILE
export const getMyProfile = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/profile`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// UPDATE MY PROFILE INFO
export const updatePasswordAccountFn = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/profile`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};
