// ++++++++ USERS ++++++++ //

import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";

// ALL USERS BY PARKINGID
export const getUsersByParkingId = async (id) => {
  const url = `${BASE_URL}/api/v2/manager/user/all/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};
