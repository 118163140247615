// ++++++++ BOOKING ++++++++ //

import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";

// BOOKINGS STATUS ALL
export const getBookingStatusAll = async (requestData) => {
  const url = `${BASE_URL}/api/v2/manager/status/booking`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// ALL BOOKINGS BY PARKINGID
export const getBookingsByParkingId = async (id) => {
  const url = `${BASE_URL}/api/v2/manager/booking/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// BOOKING USER BY ID
export const getUserBookingById = async (_id) => {
  const url = `${BASE_URL}/api/v2/booking/user/${_id}`;
  const json = await callApi(url, "GET", getToken());
  return json;
};

// UPDATE BOOKINGS BY USER_ID
export const updateBookingUser = async (_idBooking, _idUser, requestData) => {
  const url = `${BASE_URL}/api/v2/booking/user/admin/${_idBooking}/${_idUser}`;
  const json = await callApi(url, "PUT", getToken(), requestData);
  return json;
};
