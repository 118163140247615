import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";


//HARDWARES BY PARKING ID
export const getHardwaresByParkingId = async (id) => {
    const url = `${BASE_URL}/api/v2/manager/hardware/${id}`;
    const json = await callApi(url, "GET", getToken(), null);
    return json;
  };
  