import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";

// ++++++++ EVENTS ++++++++ //

//EVENTS BY PARKINGID
export const getEventsByParkingId = async (request) => {
  const url = `${BASE_URL}/api/v2/manager/event/get/all`;
  const json = await callApi(url, "POST", getToken(), request);
  return json;
};
