// ++++++++ PARKING SCHEDULER ADVANCED++++++++ //

import { BASE_URL } from "../config";
import { callApi, getToken } from "../utils";

//PARKING SCHEDULER ADVANCED ALL
export const getAllParkingSchedulersAdvanced = async (idParking) => {
  const url = `${BASE_URL}/api/v2/manager/schedulers/all/${idParking}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING SCHEDULER CREATE ADVANCED
export const createParkingSchedulerAdvanced = async (
  idParking,
  requestData
) => {
  const url = `${BASE_URL}/api/v2/manager/schedulers/${idParking}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};
//PARKING SCHEDULER UPDATE ADVANCED
export const updateParkingSchedulerAdvanced = async (
  idParking,
  requestData
) => {
  const url = `${BASE_URL}/api/v2/manager/schedulers/${idParking}`;
  const json = await callApi(url, "PUT", getToken(), requestData);
  return json;
};

//PARKING SCHEDULER DELETE ADVANCED
export const deleteParkingSchedulerAdvanced = async (idParking, idRegister) => {
  const url = `${BASE_URL}/api/v2/manager/schedulers/${idParking}/${idRegister}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
